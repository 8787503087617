var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.dialogVisible, top: "7vh" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("备课开关")])]
      ),
      _c("el-tree", {
        ref: "eltreeData",
        attrs: {
          data: _vm.treeList,
          "show-checkbox": "",
          "node-key": "id",
          "default-expand-all": true,
          "default-checked-keys": _vm.checkedArray,
          props: _vm.defaultProps,
        },
      }),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.saveForm },
            },
            [_vm._v("保 存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { size: "small", type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }