import $axios from '@/utils/request'



// 获取备课课程列表
export function $getPreparationCourseListByUser(data) {
  const url = '/coursePreparation/getPreparationCourseListByUser'
  return $axios.fPost(url, data)
}

// 获取课节列表
export function $courseUnitList(data) {
  const url = '/coursePreparation/list'
  return $axios.fPost(url, data)
}

// 获取商户列表
export function $getMerchantList(data) {
  const url = '/coursePreparation/getMerchantList'
  return $axios.fGet(url, data)
}
// 批量添加备课老师
export function $addCoursePreparationUser(data) {
  const url = '/coursePreparation/chooseCoursePreparationUserOther'
  return $axios.fPost(url, data)
}
